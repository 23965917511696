import React from "react";

export const Header = (props) => {
  return (
    <div className="intro">
    <header id="header">
     
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="responsive-heading" style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontSize:'38px'}}>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p className="responsive-paragraph" style={{fontSize:'37px',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Conoce más
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      
    </header>
    </div>
  );
};